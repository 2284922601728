<template>
    <div class="analyseDetail">
        <div class="analyse-header" v-if="analyseInfo.content">
            {{analyseInfo.content.title}}
            <el-button class="determine-btn" type="primary" @click="goBack">返回</el-button>
        </div>
        <el-scrollbar class="analyse-content">
            <div class="content-item" v-if="portrayalAnalyse">
                <div class="item-header">
                    <div class="header-block"></div>
                    <span>{{portrayalAnalyse.title}}</span>
                </div>
                <div class="item-content">
                    <div v-html="portrayalAnalyse.content"></div>
                </div>
            </div>
            <div class="content-item" v-if="accountAnalyse">
                <div class="item-header">
                    <div class="header-block"></div>
                    <span>{{accountAnalyse.title}}</span>
                </div>
                <div class="item-content">
                    <div v-html="accountAnalyse.content"></div>
                </div>
            </div>
            <div class="content-item" v-if="selectionAnalyse">
                <div class="item-header">
                    <div class="header-block"></div>
                    <span>{{selectionAnalyse.title}}</span>
                </div>
                <div class="item-content">
                    <div v-html="selectionAnalyse.content"></div>
                </div>
            </div>
            <div class="content-item" v-if="videoAnalyse">
                <div class="item-header">
                    <div class="header-block"></div>
                    <span>{{videoAnalyse.title}}</span>
                </div>
                <div class="item-content">
                    <div v-html="videoAnalyse.content"></div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "analyseDetail",
        computed: {
            ...mapGetters(['getWorksInfo'])
        },
        data() {
            return {
                id: this.$route.query.id || '',
                analyseInfo: {},
                portrayalAnalyse: null, // 人群画像
                accountAnalyse: null, // 账号分析
                selectionAnalyse: null, // 选品分析
                videoAnalyse: null, // 视频分析
            }
        },
        mounted() {
            if (this.getWorksInfo.task_works_library.length) {
                this.getWorksInfo.task_works_library.map(item => {
                    if (item.id === this.id) {
                        this.analyseInfo = item
                    }
                })
                let content = this.analyseInfo.content;
                this.portrayalAnalyse = JSON.parse(content.portrayal_analyse);
                this.accountAnalyse = JSON.parse(content.account_analyse);
                this.selectionAnalyse = JSON.parse(content.selection_analyse);
                this.videoAnalyse = JSON.parse(content.video_analyse);
            }
        },
        methods: {
            goBack() {
                localStorage.setItem('currentClass', '6')
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .analyseDetail {
        height: 100%;
        display: flex;
        flex-direction: column;

        .analyse-header {
            color: #3A3567;
            font-size: 18px;
            line-height: 1;
            position: relative;

            .determine-btn {
                position: absolute;
                right: 0;
                top: -14px;
                z-index: 999;
                padding: 10px 36px;
                background-color: #2338E6;
                border: 1px solid #2338E6;

                &:hover {
                    background-color: #1b2dbf;
                    border: 1px solid #1b2dbf;
                }
            }
        }

        .analyse-content {
            margin-top: 12px;
            height: calc(100vh - 130px);
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .content-item {
                margin-top: 20px;
                background: #FFFFFF;
                box-shadow: 0px 0px 17px 1px rgba(11, 12, 28, 0.09);
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                padding: 20px 30px;

                &:nth-child(1) {
                    margin-top: 0;
                }

                .item-header {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #DDDDDD;
                    padding-bottom: 20px;

                    .header-block {
                        width: 8px;
                        height: 15px;
                        background: #9ACC5B;
                        border-radius: 4px;
                        margin-right: 8px;
                    }

                    span {
                        color: #3A3567;
                        font-size: 18px;
                    }
                }

                .item-content {
                    padding-top: 10px;

                    ::v-deep img {
                        max-width: 100%;
                    }

                    ::v-deep a {
                        color: #1D33E1;

                        &:hover {
                            color: #5F55F1;
                        }
                    }

                    ::v-deep table {
                        border-collapse: collapse !important;
                        width: 100% !important;
                        border-color: transparent;

                        tr {
                            &:nth-child(even) {
                                td {
                                    background: #fff;
                                }
                            }

                            &:nth-child(odd) {
                                td {
                                    background: #E3E2F4;
                                }
                            }

                            th {
                                text-align: center;
                                padding: 12px 20px;
                                height: 20px !important;
                                line-height: 20px;
                                box-sizing: border-box;
                                font-size: 16px;
                                color: #fff;
                                white-space: nowrap;
                                background: #5F55F1;
                                font-weight: normal;
                            }

                            td {
                                text-align: center;
                                padding: 12px 20px;
                                font-size: 16px;
                                color: #3A3567;
                            }
                        }
                    }
                }
            }
        }
    }
</style>